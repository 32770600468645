import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import coin_icon from "../images/coin-icon.png";
import quizData from "./quiz_quuestions.json";
import Footer from "./Footer";
import AdsenseContent3 from "./AdsenseContent3";

export default function PlayQuiz() {
  const { activeCategory, quizKey } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30); // Timer for the entire quiz
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const subcategoryQuestions = quizData.data[activeCategory][quizKey];
    const shuffledQuestions = subcategoryQuestions.sort(
      () => Math.random() - 0.5
    );
    setQuestions(shuffledQuestions.slice(0, 10));
    setCurrentQuestionIndex(0);
    setCorrectCount(0);
    setWrongCount(0);
    setTimeLeft(30);
    setQuizCompleted(false);
  }, [activeCategory, quizKey]);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleQuizCompletion();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleAnswerSelection = (selectedOption, correctAnswer) => {
    if (answered) return; // Prevent multiple answers

    setSelectedAnswer(selectedOption);
    setAnswered(true);
    if (selectedOption === correctAnswer) {
      setCorrectCount((prevCount) => prevCount + 1);
    } else {
      setWrongCount((prevCount) => prevCount + 1);
    }
    
    // Proceed to next question after delay
    setTimeout(() => {
      handleNextQuestion();
    }, 1000);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setAnswered(false);
      setSelectedAnswer(null);
    } else {
      handleQuizCompletion();
    }
  };

  const handleQuizCompletion = () => {
    setQuizCompleted(true);
    navigate("/Scoreboard", { state: { correctCount, wrongCount } });
  };

  if (
    !questions ||
    questions.length === 0 ||
    currentQuestionIndex >= questions.length
  ) {
    return <div>Loading...</div>;
  }

  const { question, answer, correct } = questions[currentQuestionIndex];

  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_game_cato_que_page_wrap">
            <div className="atmequiz_game_cato_que_page_container">
              <div className="atmequiz_game_cato_que_page_row">
                <div className="atmequiz_game_cato_que_page_grid">
                  <div className="atmequiz_game_cato_que_game_about txt_center">
                    <span className="atmequiz_game_cato_que_game_name">
                      {activeCategory}
                    </span>
                    <h3 className="atmequiz_que_game_play_win_text">
                      Play and Win{" "}
                      {quizData.data[activeCategory][quizKey][0].win_prize}
                      <img src={coin_icon} alt="coin-icon" />
                    </h3>
                  </div>
                </div>
                <div className="atmequiz_game_cato_que_content_box">
                  <div className="atmequiz_game_que_page_card">
                    <div className="atmequiz_game_que_page_card_head">
                      <div className="atmequiz_game_que_page_card_time_count_row flex_align_center js_between">
                        <div className="atmequiz_game_que_page_card_correct_que">
                          {correctCount}
                        </div>
                        <div
                          className="atmequiz_game_que_card_timer_wrapper"
                          id="progressBar"
                        >
                          <svg
                            className="donut"
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g className="timer_baseTimer__circle__1YodV">
                              <circle
                                className="timer_baseTimer__pathElapsed__D6lCz"
                                cx="50"
                                cy="50"
                                r="45"
                                id="c1"
                              ></circle>
                              <circle
                                className="timer_baseTimer__pathElapsed"
                                cx="50"
                                cy="50"
                                id="c2"
                                r="39"
                              ></circle>
                              <path
                                id="base-timer-path-remaining"
                                stroke-dasharray="283 283"
                                className="timer_baseTimer__pathRemaining__PkIN0 timer_green__SVzgO"
                                d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                              ></path>
                            </g>
                          </svg>
                          <span
                            className="timer_seconds__2KixA"
                            id="counterText"
                          >
                            {("0" + Math.floor(timeLeft / 60)).slice(-2)}:
                            {("0" + (timeLeft % 60)).slice(-2)}
                          </span>
                        </div>
                        <div className="atmequiz_game_que_page_card_Incorrect_que">
                          {wrongCount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="atmequiz_game_cato_que_content_box">
                  <div className="atmequiz_game_que_page_card">
                    <div className="atmequiz_game_que_card_body txt_center">
                      <div className="atmequiz_game_que_card_content_wrap">
                        <div className="atmequiz_game_quiz_card_que_number flex_align_center js_center">
                          <p className="atmequiz_game_que_card_number_text">
                            Question{" "}
                            <span className="atmequiz_game_que_card_curr_que_num">
                              {currentQuestionIndex + 1}
                            </span>{" "}
                            /{" "}
                            <span className="atmequiz_game_que_card_total_que_num">
                              {questions.length}
                            </span>
                          </p>
                        </div>
                        <h4 className="atmequiz_game_quize_card_que_title">
                          {question}
                        </h4>
                        <div className="atmequiz_game_option_box">
                          <ul className="atmequiz_game_option_list">
                            {answer.map((option, index) => (
                              <li
                                key={index}
                                className="flex_align_center js_center txt_center"
                              >
                                <button
                                  onClick={() =>
                                    handleAnswerSelection(option, correct)
                                  }
                                  className={`atmequiz_option_list_item flex_align_center js_center`}
                                  style={{
                                    backgroundColor: !answered
                                      ? selectedAnswer === option
                                        ? option === correct
                                          ? "#92db92"
                                          : "#f35757"
                                        : ""
                                      : option === correct
                                      ? "#92db92"
                                      : selectedAnswer === option &&
                                        option !== correct
                                      ? "#f35757"
                                      : "",
                                  }}
                                  disabled={answered}
                                >
                                  {option}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdsenseContent3 />
          <Footer />
        </div>
      </div>
    </main>
  );
}
