import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import AdsenseContent from './AdsenseContent';
import Footer from "./Footer";

export default function AboutUs() {
  return (
    <main>
      <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
          <div className="atmequiz_main_page_inner">
            <div class="atmequiz_game_contact_container">
              <div class="atmequiz_game_contact_row">
                <div class="atmequiz_game_contact_wrapper">
                  <div class="atmequiz_game_contact_grid">
                    <h2 class="atmequiz_game_contact_section_title">
                      About Us!
                    </h2>
                    <div class="atmequiz_game_contact_detail_box">
                      <h4>Lorem Ipsum</h4>
                      <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's 
                      standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a 
                      type specimen book. It has survived not only five 
                      centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                      </p>
                    </div>
                    <div className="atmequiz_game_quiz_cato_detail_box">
                      <div className="atmequiz_game_quiz_cato_detail_item">
                            <h6>Join the Fun</h6>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a 
                                page when looking at its layout. The point of using Lorem Ipsum
                                 is that it has a more-or-less normal distribution of letters,!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdsenseContent />
          <Footer></Footer>
        </div>
      </div>
    </main>
  );
}
