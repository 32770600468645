import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Category from './components/Category';
import Main from './components/Main';
import JoinContest from './components/join_contest';
import Contest_rules from './components/Contest_rules';
import Login from './components/Login';
import PlayQuiz from './components/playQuiz';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Scoreboard from './components/Scoreboard';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/JoinContest/:login" element={<Login />} />
          <Route path="/Contest-rules/:JoinContest" element={<Contest_rules />} />
          <Route path="/Scoreboard" element={<Scoreboard correctCount={5} wrongCount={2} redirectedFromPlayQuiz={false} />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/join/:activeCategory/:quizKey" element={<JoinContest />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/" element={<Main />} />
          <Route path="/play/:activeCategory/:quizKey" element={<PlayQuiz />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
