//join_contest.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import coin_icon from '../images/coin-icon.png';
import close_icon from '../images/close_icon.svg';
import wrong_coinsBox from '../images/wrong_coinsBox.svg';
import videoIcon from '../images/videoIcon.svg';
import quizData from './quiz_quuestions.json';
import AdsenseContent from './AdsenseContent';
import Footer from './Footer';

export default function JoinContest() {
    const { activeCategory, quizKey } = useParams();
    const [showPrizeList, setShowPrizeList] = useState(false);
    const [totalCoins, setTotalCoins] = useState(10);

    const togglePrizeList = () => {
        setShowPrizeList(!showPrizeList);
    };

    const [showVideoIcon, setshowVideoIcon] = useState(false);

    const toggleshowVideoIcon = () => {
        setshowVideoIcon(!showVideoIcon);
    };
    const [coins, setCoins] = useState(null);
    useEffect(() => {
        const fetchData = () => {
            if (quizData.data && quizData.data[activeCategory] && quizData.data[activeCategory][quizKey]) {
                setCoins(quizData.data[activeCategory][quizKey][0].coins);
            } else {
                setCoins(null);
            }
        };
        fetchData();
    }, [activeCategory, quizKey]);
    
    const redirectToPage = () => {
        window.location.href = '/';
      };
  
  return (
    <main>
    <div className="atmequiz_game_page_body">
        <div className="atmequiz_game_page_main">
            <div className="atmequiz_main_page_inner">
            <div className="atmequiz_game_quiz_cato_detail_box">
                <div className="atmequiz_game_quiz_cato_detail_item">
                    <div className="atmequiz_game_quiz_cato_img flex_align_center js_center">
                    <img src={`${process.env.PUBLIC_URL}/QuizCategory/${quizData.data[activeCategory][quizKey][0].image}`} className='image_cover' />
                    </div>
                    <div className="atmequiz_game_quiz_cato_contact_box txt_center">
                        <span className="atmequiz_game_quiz_cato_name">{quizKey}</span>
                        <h3 className="atmequiz_game_quiz_cato_title">
                            Play and Win {quizData.data[activeCategory][quizKey][0].win_prize}
                            <span className="atmequiz_game_quiz_cato_coin_img">
                               <img src={coin_icon} alt="coin-icon"/>
                            </span>
                        </h3>
                        <div className="atmequiz_game_quiz_cato_desc_box">
                            <p className="atmequiz_game_quiz_cato_desc_text_info">
                                You’ve got <span className="atmequiz_game_quiz_cato_second_text">60</span>  seconds to answer all questions. Answer as many questions as you can. Entry fee will be 
                                <span className="atmequiz_game_quiz_cato_entry_free_coin">
                                    <span className="atmequiz_game_quiz_cato_total_coin"> {coins !== null ? `Entry: ${coins} coins` : "Quiz not found"} </span>
                                   <img src={coin_icon} alt="coin-icon"/>
                                </span>
                            </p>
                            <p className="atmequiz_game_quiz_cato_desc_text">Join and save the coins you win! Its free & safe!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="atmequiz_game_quiz_cato_button_box">
                <div className="atmequiz_game_quiz_cato_join_button_div">
                    <Link to={`/play/${activeCategory}/${quizKey}`} className='buttonBG'>
                        <button className="atmequiz_game_quiz_cato_join_btn primary_button">
                            Join Quiz
                        </button>
                    </Link>
                </div>
                <div className="atmequiz_game_quiz_cato_play_guest_button_div">
                        <button className="atmequiz_game_quiz_cato_play_guest_btn second_button primary_button" onClick={toggleshowVideoIcon}>
                            {showPrizeList ? 'Play as Guest' : 'Play as Guest'}
                        </button>
                </div>
            </div>
            <div className={`atmequiz_game_popup_body video_get_coin_popup_body ${showVideoIcon ? '' : 'hide'}`}>
                    <div className="atmequiz_game_popup_container bonusModal_report__kFJSI">
                    <div className="bonusModal_wrapper__IpNgp">
                        <div className="atmequiz_game_grid">
                            <div className="atmequiz_game_popup_box">
                                <div className="atmequiz_game_popup_content">
                                    <div className="atmequiz_game_close_div">
                                    <button  class="atmequiz_game_close_icon" onClick={toggleshowVideoIcon}>
                                        <img className='icon_postion' src={close_icon} alt="close_icon"/>
                                    </button>
                                    </div>
                                    <div className="atmequiz_game_img">
                                        <img src={wrong_coinsBox} alt="wrong_coinsBox"/>
                                    </div>
                                    <h3 className="atmequiz_game_oops_text">Oops!</h3>
                                    <p className="atmequiz_game_desc_text">You don't have enough coins to play this contest.</p>
                                    <div className="atmequiz_game_button_desc_div">
                                        <div className="atmequiz_game_button_box flex_align_center js_center">
                                            <button
                                            className="atmequiz_game_button_row flex_align_center js_center"
                                            id="watchAdButton"
                                            onClick={redirectToPage}
                                            >
                                            <img src={videoIcon} alt="videoIcon" />
                                            <span className="atmequiz_game_btn_text">Continue</span>
                                            </button>
                                        </div>
                                        <p className="atmequiz_game_desc_text">Click on video ad to get 100 reward coins.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
            <AdsenseContent />
            <div className="atmequiz_game_quiz_cato_prize_contact_box">
                    <div className="atmequiz_game_quiz_cato_prize_contact_row flex_align_center">
                        <div className="atmequiz_game_quiz_cato_prize_contact_button_div"> 
                        <button className="atmequiz_game_quiz_cato_prize_btn second_button primary_button" onClick={togglePrizeList}>
                            {showPrizeList ? 'Hide Prize' : 'View Prize'}
                        </button>
                        </div>
                        <div className="atmequiz_game_quiz_cato_prize_contact_button_div"> 
                            <Link to="/Contest-rules/JoinContest" className="second_button primary_button">Contest Prize
                            </Link>
                        </div>
                    </div>
            </div>
            </div>
             <div className={`atmequiz_game_prize_body ${showPrizeList ? '' : 'hide'}`}>
                    <div class="atmequiz_game_prize_container">
                        <div class="atmequiz_game_prize_detail_box">
                            <div class="atmequiz_game_prize_content_grid">
                                <h3 class="atmequiz_game_prize_content_title">Prize Rank List</h3>
                                <div class="atmequiz_game_close_div">
                                <button className="atmequiz_game_close_icon" onClick={togglePrizeList}>
                                    <img src={close_icon} className='icon_postion' alt="close_icon"/>
                                </button>

                                </div>
                                <div class="atmequiz_game_prize_report_box">
                                    <div class="atmequiz_game_prize_report_grid">
                                        <div class="atmequiz_game_prize_report_list_item">
                                            <div class="atmequiz_game_prize_list_row flex_align_center js_between">
                                                <div class="atmequiz_game_prize_list_subtitle">
                                                    <span class="atmequiz_game_prize_list_rank_text">Rank 1</span>
                                                </div>
                                                <div class="atmequiz_game_prize_report_coin_wrap">
                                                    <div class="atmequiz_game_prize_report_coin_div">
                                                        <span class="atmequiz_game_prize_report_coin_text">4000</span>
                                                        <span class="atmequiz_game_prize_report_coin_icon">
                                                            <img src={coin_icon} alt="coin-icon"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="atmequiz_game_prize_report_list_item">
                                            <div class="atmequiz_game_prize_list_row flex_align_center js_between">
                                                <div class="atmequiz_game_prize_list_subtitle">
                                                    <span class="atmequiz_game_prize_list_rank_text">Rank 2 - 10</span>
                                                </div>
                                                <div class="atmequiz_game_prize_report_coin_wrap">
                                                    <div class="atmequiz_game_prize_report_coin_div">
                                                        <span class="atmequiz_game_prize_report_coin_text">2000</span>
                                                        <span class="atmequiz_game_prize_report_coin_icon">
                                                            <img src={coin_icon} alt="coin-icon"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="atmequiz_game_prize_report_list_item">
                                            <div class="atmequiz_game_prize_list_row flex_align_center js_between">
                                                <div class="atmequiz_game_prize_list_subtitle">
                                                    <span class="atmequiz_game_prize_list_rank_text">Rank 11 - 50</span>
                                                </div>
                                                <div class="atmequiz_game_prize_report_coin_wrap">
                                                    <div class="atmequiz_game_prize_report_coin_div">
                                                        <span class="atmequiz_game_prize_report_coin_text">1200</span>
                                                        <span class="atmequiz_game_prize_report_coin_icon">
                                                            <img src={coin_icon} alt="coin-icon"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="atmequiz_game_prize_report_list_item">
                                            <div class="atmequiz_game_prize_list_row flex_align_center js_between">
                                                <div class="atmequiz_game_prize_list_subtitle">
                                                    <span class="atmequiz_game_prize_list_rank_text">Rank 51 - 200</span>
                                                </div>
                                                <div class="atmequiz_game_prize_report_coin_wrap">
                                                    <div class="atmequiz_game_prize_report_coin_div">
                                                        <span class="atmequiz_game_prize_report_coin_text">600</span>
                                                        <span class="atmequiz_game_prize_report_coin_icon">
                                                            <img src={coin_icon} alt="coin-icon"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="atmequiz_game_prize_report_list_item">
                                            <div class="atmequiz_game_prize_list_row flex_align_center js_between">
                                                <div class="atmequiz_game_prize_list_subtitle">
                                                    <span class="atmequiz_game_prize_list_rank_text">Rank 201 - 500</span>
                                                </div>
                                                <div class="atmequiz_game_prize_report_coin_wrap">
                                                    <div class="atmequiz_game_prize_report_coin_div">
                                                        <span class="atmequiz_game_prize_report_coin_text">300</span>
                                                        <span class="atmequiz_game_prize_report_coin_icon">
                                                            <img src={coin_icon} alt="coin-icon"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="atmequiz_game_prize_report_list_item">
                                            <div class="atmequiz_game_prize_list_row flex_align_center js_between">
                                                <div class="atmequiz_game_prize_list_subtitle">
                                                    <span class="atmequiz_game_prize_list_rank_text">Rank 501 - 1000</span>
                                                </div>
                                                <div class="atmequiz_game_prize_report_coin_wrap">
                                                    <div class="atmequiz_game_prize_report_coin_div">
                                                        <span class="atmequiz_game_prize_report_coin_text">100</span>
                                                        <span class="atmequiz_game_prize_report_coin_icon">
                                                            <img src={coin_icon} alt="coin-icon"/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <Footer></Footer>
            </div>
            </div>
            </main>
  )
}

