import React from "react";
import AdSense from 'react-adsense';

export default function AdsenseContent() {
  return (
    <section className="atmequiz_main_page_text_ad_section">
      <div className="atmequiz_game_page_add_text_gridbox">
        <div className="atmequiz_game_page_container">
          <div className="atmequiz_game_page_text_ad_div">
            <span className="atmequiz_page_span_text">Block 1</span>
            <div className="atmequiz_game_page_ad_box">
              <a href="/">
                <img src="" alt="" />
                <AdSense.Google
                    client='ca-pub-7292810486004926'
                    slot='780'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                    layoutKey='-gw-1+2a-9x+5c'
                  />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
